<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex align-items-center mb-4">
                <div style="width: 60%">
                    <b-input-group>
                        <b-form-input
                            id="filter-input"
                            type="text"
                            placeholder="Buscar registros"
                            class="bg-background-input"
                            v-model="search"
                            @keydown.enter="searchData"
                            @input="search === '' && searchData()"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button
                                variant="background-input"
                                class="text-blue-active d-flex align-items-center"
                                @click="searchData()"
                            >
                                <feather type="search" size="1.1rem"></feather>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div class="ml-auto">
                    <div
                        class="d-sm-flex mt-4 mt-sm-0 justify-content-end align-items-center"
                    >
                        <!-- <input
                            type="file"
                            ref="excelInput"
                            id="excelInput"
                            style="display: none"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="changeFile"
                        />
                        <b-button
                            variant="primary"
                            size="md"
                            class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                            @click="addRegister"
                            v-b-popover.hover.top="'Presione alt+a o option+a'"
                            :disabled="
                                !permisos.includes('lote_inventario-crear')
                            "
                        >
                            <feather
                                type="plus"
                                size="1rem"
                                class="mr-2 blue-active-text"
                            ></feather>
                            Nuevo Registro
                        </b-button> -->
                        <b-dropdown
                            id="dropdown-divider"
                            text="Opciones"
                            size="md"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            :disabled="
                                !permisos.includes(
                                    'inventario-movimiento-query-export'
                                ) &&
                                !permisos.includes(
                                    'inventario-movimiento-query-export-and-email'
                                )
                            "
                        >
                            <template #button-content>
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <feather
                                        type="more-horizontal"
                                        size="1.5rem"
                                        class="text-secondary"
                                    ></feather>
                                </div>
                            </template>
                            <b-dropdown-item-button
                                v-for="(format, index) in formats"
                                :key="index"
                                @click="verifyFields(format.ext)"
                                :disabled="
                                    !permisos.includes(
                                        'inventario-movimiento-query-export'
                                    )
                                "
                            >
                                {{ format.name }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                @click="$bvModal.show('email-modal')"
                                :disabled="
                                    !permisos.includes(
                                        'inventario-movimiento-query-export-and-email'
                                    )
                                "
                                >Enviar informe por
                                Correo</b-dropdown-item-button
                            >
                            <!-- <b-dropdown-divider></b-dropdown-divider> -->
                            <!-- <b-dropdown-item-button
                                @click="getImportFormat(moduleName)"
                                :disabled="
                                    !permisos.includes(
                                        'lote_inventario-crud-export'
                                    )
                                "
                                >Plantilla para importación de
                                tabla</b-dropdown-item-button
                            >
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item-button
                                @click="$refs.excelInput.click()"
                                :disabled="
                                    !permisos.includes(
                                        'lote_inventario-crud-import'
                                    )
                                "
                                >Cargar Plantilla para importación de
                                tabla</b-dropdown-item-button
                            > -->
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </b-col>
        <b-col cols="12" lg="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100 h-100">
                <div class="table-responsive mt-3" v-hotkey="keymap">
                    <b-table
                        :fields="fields"
                        :items="movimientos"
                        :tbody-tr-class="rowClass"
                        borderless
                        responsive
                        head-variant="light"
                        class="mb-0 hover-table text-nowrap"
                    >
                        <template #cell(fecha)="data">
                            <span>
                                {{ data.value.split('T')[0] }}
                            </span>
                        </template>
                        <template #cell(cliente)="data">
                            <span>
                                {{ getCliente(data.item) }}
                            </span>
                        </template>
                        <template #cell(acciones)="data">
                            <!-- <feather
                                v-if="
                                    permisos.includes('lote_inventario-editar')
                                "
                                type="edit"
                                role="button"
                                class="mr-2"
                                @click="
                                    $router.push({
                                        name: 'Editar lote inventario',
                                        params: {
                                            lote_inventario_id: data.item.id
                                        }
                                    })
                                "
                            ></feather> -->
                            <!-- <feather
                                v-else
                                type="edit"
                                role="button"
                                class="mr-2 text-secondary"
                            ></feather> -->
                            <!-- <feather
                                v-if="
                                    permisos.includes(
                                        'lote_inventario-eliminar'
                                    )
                                "
                                type="trash-2"
                                role="button"
                                @click="showConfirm(data.item)"
                            ></feather> -->
                            <!-- <feather
                                v-else
                                type="trash-2"
                                role="button"
                                class="text-secondary"
                            ></feather> -->
                            <feather
                                type="eye"
                                role="button"
                                class="ml-2"
                                @click="
                                    $router.push({
                                        name: 'Ver movimiento de inventario',
                                        params: {
                                            movimiento_id: data.item.id
                                        }
                                    })
                                "
                            ></feather>
                        </template>
                    </b-table>
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Número de registros"
                        label-for="per-page-select"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="pagination.perPage"
                            :options="pagination.pageOptions"
                            @change="getMovimientosInventario('', 1, $event)"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            first-number
                            last-number
                            size="sm"
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalRows"
                            :per-page="pagination.perPage"
                            @input="
                                getMovimientosInventario(
                                    '',
                                    $event,
                                    pagination.perPage
                                )
                            "
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-card>
            <selectFieldsReport
                :modulo="moduleName"
                :format="selectedFormat"
                :fields="
                    fieldsAvailables[
                        selectedFormat ? selectedFormat.toLowerCase() : 'excel'
                    ]
                "
                @close="selectedFormat = null"
            />
            <emailModal
                :sender="configEmail"
                :modulo="firstToUpperString(moduleName)"
                :fieldsAvailables="fieldsAvailables"
            />
        </b-col>
        <b-col cols="12">
            <div
                class="d-sm-flex mt-4 justify-content-between align-items-center"
            >
                <!-- <b-button
                    variant="primary"
                    size="md"
                    class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                    @click="addRegister"
                    v-b-popover.hover.top="'Presione alt+a o option+a'"
                    :disabled="!permisos.includes('lote_inventario-crear')"
                >
                    <feather
                        type="plus"
                        size="1rem"
                        class="mr-2 blue-active-text"
                    ></feather>
                    Nuevo Registro
                </b-button> -->
                <b-dropdown
                    id="dropdown-divider"
                    text="Opciones"
                    size="md"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    :disabled="
                        !permisos.includes('inventario-movimiento-query-export') &&
                        !permisos.includes(
                            'inventario-movimiento-query-export-and-email'
                        )
                    "
                >
                    <template #button-content>
                        <div
                            class="d-flex justify-content-center align-items-center"
                        >
                            <feather
                                type="more-horizontal"
                                size="1.5rem"
                                class="text-secondary"
                            ></feather>
                        </div>
                    </template>
                    <b-dropdown-item-button
                        v-for="(format, index) in formats"
                        :key="index"
                        @click="verifyFields(format.ext)"
                        :disabled="
                            !permisos.includes('inventario-movimiento-query-export')
                        "
                    >
                        {{ format.name }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        @click="$bvModal.show('email-modal')"
                        :disabled="
                            !permisos.includes(
                                'inventario-movimiento-query-export-and-email'
                            )
                        "
                        >Enviar informe por Correo</b-dropdown-item-button
                    >
                    <!-- <b-dropdown-divider></b-dropdown-divider> -->
                    <!-- <b-dropdown-item-button
                        @click="getImportFormat(moduleName)"
                        :disabled="
                            !permisos.includes('lote_inventario-crud-export')
                        "
                        >Plantilla para importación de
                        tabla</b-dropdown-item-button
                    >
                    <b-dropdown-divider></b-dropdown-divider> -->
                    <!-- <b-dropdown-item-button
                        @click="$refs.excelInput.click()"
                        :disabled="
                            !permisos.includes('lote_inventario-crud-import')
                        "
                        >Cargar Plantilla para importación de
                        tabla</b-dropdown-item-button
                    > -->
                </b-dropdown>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters, mapState, mapActions } from 'vuex'
import selectFieldsReport from '@/components/shared/select-fields-report/select-fields-report'
import emailModal from '@/components/shared/email/email'
import { firstToUpperString } from '@/utils/others'
import { addListenerCommands, removeListenerCommands } from '@/utils/others'
// import * as XLSX from 'xlsx'

const { API } = services

export default {
    name: 'MainLotesInventario',
    components: { selectFieldsReport, emailModal },
    mounted() {
        addListenerCommands(this)
        this.getMovimientosInventario()
        this.getEmpresaEmails()
        this.getAvailableFields()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    data: () => ({
        fields: [
            // { key: "codigo", label: "Código" },
            { key: 'tipoMovimiento', label: 'Tipo movimiento' },
            { key: 'fecha', label: 'Fecha' },
            { key: 'cliente', label: 'Establecimiento' },
            { key: 'acciones', label: 'Acciones' }
        ],
        movimientos: [],
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        search: '',
        fieldsAvailables: {},
        configEmail: null,
        selectedFormat: null,
        moduleName: 'inventario-movimiento',
        formats: [
            { id: 1, name: 'Generar Informe Excel', ext: 'EXCEL' },
            { id: 2, name: 'Generar Informe Pdf', ext: 'PDF' }
        ]
    }),
    methods: {
        showLog(data) {
            console.log(data)
        },
        firstToUpperString,
        searchData() {
            this.getMovimientosInventario(this.search)
        },
        showConfirm({ id, descripcion }) {
            this.$bvModal
                .msgBoxConfirm(
                    `Usted está apunto de eliminar el lote de inventario "${descripcion}", desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.deleteLoteInventario(id)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getMovimientosInventario(
            term = '',
            page = 1,
            limit = this.pagination.perPage
        ) {
            API.POST({
                url: 'administracion/inventario/inventario-movimiento/filter',
                data: {
                    page,
                    term,
                    limit,
                    empresaId: this.getUltimaEmpresa.id
                }
            })
                .then((response) => {
                    console.log(response)
                    const {
                        data: {
                            dato: { content, totalContent, size, number },
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.movimientos = content
                        this.pagination = {
                            ...this.pagination,
                            totalRows: totalContent,
                            perPage: size,
                            currentPage: number
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        verifyFields(ext) {
            this.selectedFormat = ext
            this.$bvModal.show('modal-fields-report')
        },
        getAvailableFields() {
            if (this.permisos.includes('inventario-movimiento-query-export')) {
                API.POST({
                    url: 'report/available-fields',
                    data: {
                        p_datajson: {
                            modulo: this.moduleName
                        },
                        p_opc: 'GET_FIELD',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato);
                            this.fieldsAvailables = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        // changeFile(evt) {
        //     if (evt) {
        //         const file = evt.target.files[0]
        //         const reader = new FileReader()

        //         reader.onloadend = (e) => {
        //             const data = e.target.result
        //             const workbook = XLSX.read(data, { type: 'binary' })
        //             const sheet = workbook.SheetNames[0]
        //             const XL_ROW_OBJECT = XLSX.utils.sheet_to_json(
        //                 workbook.Sheets[sheet]
        //             )
        //             if (XL_ROW_OBJECT.length > 0) {
        //                 API.POST({
        //                     url: 'report/form/import',
        //                     data: {
        //                         p_datajson: {
        //                             datos: [...XL_ROW_OBJECT],
        //                             empresa_id: this.getUltimaEmpresa.id
        //                         },
        //                         p_opc: this.moduleName.toUpperCase(),
        //                         p_auditoriajson: this.auditoriajson
        //                     }
        //                 })
        //                     .then((response) => {
        //                         const {
        //                             data: {
        //                                 dato,
        //                                 status: {
        //                                     status,
        //                                     mensaje,
        //                                     mensaje_exception
        //                                 }
        //                             }
        //                         } = response
        //                         this.$refs.excelInput.value = null
        //                         if (
        //                             status === 'ok' &&
        //                             dato.some((d) => d.status.status !== 'err')
        //                         ) {
        //                             this.getMovimientosInventario()
        //                         } else {
        //                             const mesaje_d = dato
        //                                 .map((d) => d.status.mensaje)
        //                                 .join(', ')
        //                             this.$bvModal
        //                                 .msgBoxOk(
        //                                     dato.some(
        //                                         (d) => d.status.status === 'err'
        //                                     )
        //                                         ? mesaje_d
        //                                         : mensaje ||
        //                                               mensaje_exception ||
        //                                               'Ha ocurrido un error!',
        //                                     {
        //                                         title: 'Error!',
        //                                         size: 'sm',
        //                                         buttonSize: 'sm',
        //                                         okVariant: 'error',
        //                                         okTitle: 'Aceptar',
        //                                         cancelTitle: 'Cancelar',
        //                                         footerClass: 'p-2',
        //                                         hideHeaderClose: false,
        //                                         centered: true,
        //                                         headerBgVariant: 'error',
        //                                         headerClass: 'modal-header'
        //                                     }
        //                                 )
        //                                 .then(() => {
        //                                     // console.log(value);
        //                                 })
        //                                 .catch((err) => {
        //                                     console.log(err)
        //                                 })
        //                         }
        //                     })
        //                     .catch((error) => {
        //                         const { message } = error
        //                         this.$bvModal
        //                             .msgBoxOk(
        //                                 message || 'Ha ocurrido un error!',
        //                                 {
        //                                     title: 'Error!',
        //                                     size: 'sm',
        //                                     buttonSize: 'sm',
        //                                     okVariant: 'error',
        //                                     okTitle: 'Aceptar',
        //                                     cancelTitle: 'Cancelar',
        //                                     footerClass: 'p-2',
        //                                     hideHeaderClose: false,
        //                                     centered: true,
        //                                     headerBgVariant: 'error',
        //                                     headerClass: 'modal-header'
        //                                 }
        //                             )
        //                             .then(() => {
        //                                 // console.log(value);
        //                             })
        //                             .catch((err) => {
        //                                 console.log(err)
        //                             })
        //                     })
        //             }
        //             // console.log(workbook)
        //         }

        //         reader.readAsBinaryString(file)
        //     }
        // },
        getEmpresaEmails() {
            API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.configEmail = dato[0]
                    } else if (dato.length === 0 && status !== 'err') {
                        this.$bvModal
                            .msgBoxConfirm(
                                'Esta empresa no posee configuración de correo para reportes o envío de documentos por email, desea crear una configuración de correo para esta empresa?',
                                {
                                    title: 'Advertencia!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'warning',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'warning',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then((value) => {
                                if (value) {
                                    this.$router
                                        .push({
                                            path: '/home/general/generales/configuracion-email/crear-configuracion-email'
                                        })
                                        .catch((error) => error)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        addRegister() {
            this.$router.push(
                '/home/administrativo/inventario/lotes-inventario/crear-lote-inventario'
            )
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        getCliente(data) {
            const { tipoMovimiento } = data
            if (tipoMovimiento === 'VENTA_CLIENTE') {
                const { ventaCliente } = data

                return ventaCliente.facturaVenta.cliente.nombreComercial
            } else if (tipoMovimiento === 'DEVOLUCION_CLIENTE') {
                const { devolucionCliente } = data

                return devolucionCliente.devolucionFactura.cliente
                    .nombreComercial
            } else if (tipoMovimiento === 'REMISION_PROVEEDOR') {
                const { remisionProveedor } = data

                return this.getLabelCliente(
                    remisionProveedor.remisionProveedor.proveedor
                )
            } else if (tipoMovimiento === 'COMPRA_PROVEEDOR') {
                const { compraProveedor } = data

                return this.getLabelCliente(
                    compraProveedor.facturaProveedor.proveedor
                )
            } else if (tipoMovimiento === 'REMISION_CLIENTE') {
                const { remisionCliente } = data

                return remisionCliente.remisionCliente.cliente.nombreComercial
            } else if (tipoMovimiento === 'DEVOLUCION_PROVEEDOR') {
                const { devolucionProveedor } = data

                return this.getLabelCliente(
                    devolucionProveedor.devolucionProveedor.proveedor
                )
            } else {
                return 'N/A'
            }
        },
        getLabelCliente(item) {
            const {
                nombre1,
                nombre2,
                apellido1,
                apellido2,
                razon_social,
                tipoDocumento
            } = item

            let label = ''

            if (tipoDocumento.id == 13) {
                label = `${nombre1}${nombre2 && ` ${nombre2}`} ${apellido1}${
                    apellido2 && ` ${apellido2}`
                }`
            } else {
                label = `${razon_social}`
            }

            return label
        },
        ...mapActions(['getImportFormat'])
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 39 }
            return json
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getPermisos'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style></style>
