<template>
    <b-modal
        :id="id"
        title="Enviar reporte por correo"
        header-bg-variant="primary"
        centered
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        hide-footer
    >
        <!-- :footer-class="`${Theme === 'dark' && 'dark'} dialog2`" -->
        <b-form>
            <b-form-group>
                <label for="sender">Formato del reporte</label>
                <b-form-select
                    id="format"
                    :options="formats"
                    text-field="descripcion"
                    value-field="id"
                    v-model="$v.format.$model"
                    :disabled="id === 'email-modal-single'"
                    :state="validateStateFormat()"
                >
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.format.required">
                    Campo requerido
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group class="mt-3">
                <label for="sender">Remitente</label>
                <b-form-select
                    id="sender"
                    :options="[sender]"
                    text-field="email"
                    value-field="id"
                    :disabled="sender !== null"
                    v-model="$v.email.sender.$model"
                    :state="validateState('sender')"
                    @change="
                        (value) =>
                            value === 'ce' &&
                            $router
                                .push({
                                    path: '/home/general/generales/configuracion-email/crear-configuracion-email'
                                })
                                .catch((error) => error)
                    "
                >
                    <template #first>
                        <b-form-select-option
                            :value="'ce'"
                            v-if="sender === null"
                        >
                            Crear configuración email
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
            <label for="emails"
                >Receptores<feather
                    type="edit"
                    size="1.1rem"
                    role="button"
                    class="ml-3"
                    @click="editEmails = true"
                ></feather
            ></label>
            <div class="mt-2" v-if="editEmails">
                <b-input-group>
                    <b-form-input
                        id="emails"
                        type="text"
                        v-model="$v.to.$model"
                        :state="validateStateTo()"
                        placeholder="Ingrese el email a agregar"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button
                            variant="success"
                            class="text-white d-flex align-items-center"
                            :disabled="!$v.to.email"
                            @click="addRemoveEmail(to), resetEmailInput()"
                        >
                            <feather type="check" size="1.1rem"></feather>
                        </b-button>
                        <b-button
                            variant="error"
                            class="text-white d-flex align-items-center"
                            @click="resetEmailInput()"
                        >
                            <feather type="x" size="1.1rem"></feather>
                        </b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback v-if="!$v.to.email">
                        E-mail inválido
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="!$v.to.required">
                        Campo requerido
                    </b-form-invalid-feedback>
                </b-input-group>
            </div>
            <div class="mt-2" v-else>
                <template v-if="email.emails.length > 0">
                    <span
                        class="badge bg-light-success align-items-center mr-1"
                        v-for="(e, i) in email.emails"
                        :key="i"
                    >
                        {{ e }}
                        <feather
                            type="x"
                            size="0.8rem"
                            class="ml-2"
                            @click="addRemoveEmail(e, i)"
                        ></feather>
                    </span>
                </template>
                <span
                    v-if="email.emails.length === 0"
                    style="font-size: 0.8rem"
                    class="text-secondary ml-2"
                >
                    Sin receptores...
                </span>
                <div
                    v-if="!$v.email.emails.requiredUnless"
                    class="invalid-field ml-2"
                >
                    Serequiere al menos un receptor
                </div>
            </div>
            <b-form-group class="mt-3">
                <label for="subject">Asunto</label>
                <b-form-input id="subject" v-model="$v.email.subject.$model" />
            </b-form-group>
            <b-form-group class="mt-2">
                <label for="email_body">Cuerpo</label>
                <b-textarea
                    id="email_body"
                    v-model="$v.email.email_body.$model"
                />
            </b-form-group>
        </b-form>
        <div class="float-right d-flex">
            <b-button variant="secondary" class="mt-4 mr-4" @click="close"
                >Cancelar</b-button
            >
            <!-- @click="close('')" -->
            <b-button variant="primary" class="mt-4" @click="save">
                <span
                    v-if="isLoading"
                    class="d-flex align-items-center justify-content-center"
                >
                    <b-spinner small></b-spinner>
                    <span class="ml-2">Enviando...</span>
                </span>
                <span v-else>Enviar</span>
            </b-button>
        </div>
    </b-modal>
</template>

<script>
// import axios from 'axios';
import { validationMixin } from 'vuelidate'
import { required, email, requiredUnless } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import services from '@/boot/axios'

const { API } = services

const defaultEmail = () =>
    JSON.parse(
        JSON.stringify({
            opc: 'enviarMails',
            empresa_id: null,
            sender: null,
            email_body: '',
            subject: '',
            emails: []
        })
    )

export default {
    name: 'EmailModal',
    mixins: [validationMixin],
    watch: {
        sender(newValue) {
            this.setEmailData(newValue)
        }
    },
    props: {
        sender: { type: Object, default: () => null },
        modulo: { type: String, default: '' },
        fieldsAvailables: { type: Object, default: () => ({}) },
        url: { type: String, default: 'report/enviar-mail' },
        alternativeOpc: { type: String, default: '' },
        extraParamsDatajson: { type: Object, default: () => ({}) },
        id: { type: String, default: 'email-modal' }
    },
    validations: {
        email: {
            sender: {
                required
            },
            emails: {
                requiredUnless: requiredUnless((email) => {
                    return email.emails.length > 0
                })
            },
            subject: {},
            email_body: {}
        },
        to: {
            required,
            email
        },
        format: {
            required
        }
    },
    mounted() {
        this.setEmailData()
        this.format = this.id === 'email-modal-single' ? 'pdf' : 'excel'
    },
    data: () => ({
        email: defaultEmail(),
        isLoading: false,
        to: '',
        editEmails: false,
        extensionsFormats: {
            excel: 'xlsx',
            pdf: 'pdf'
        },
        formats: [
            { id: 'excel', descripcion: 'Excel' },
            { id: 'pdf', descripcion: 'PDF' }
        ],
        format: 'excel'
    }),
    computed: {
        ...mapState(['Theme']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('usuario', ['getUserData']),
        ...mapGetters('sedes', ['getUltimaSede'])
    },
    methods: {
        addRemoveEmail(email, index) {
            if (index !== undefined && index >= 0) {
                this.email.emails.splice(index, 1)
            } else if (index === undefined) {
                this.email.emails.push(email)
            }
        },
        resetEmailInput() {
            this.editEmails = !this.editEmails
            this.to = ''
            setTimeout(() => {
                this.$v.to.$reset()
            }, 500)
        },
        setEmailData(s) {
            const sender = s ? s : this.sender
            if (sender !== null) {
                this.email.sender = sender.id
            }
            this.email.subject = `Reporte de ${this.modulo} ERP - Softbusiness S.A.S`
            this.email.email_body = `ERP - Softbusiness S.A.S - ${
                this.getUltimaEmpresa.razon_social
            }, le comunica el siguiente reporte:\n
        Reporte de ${this.modulo}\n
        ${new Date()}\n
      Cordialmente: ${this.getUserData.nombre1} ${this.getUserData.apellido1}`
        },
        save() {
            // console.log(this.email);
            const valid = !this.$v.email.$invalid
            if (valid) {
                this.isLoading = true
                const email = JSON.parse(JSON.stringify(this.email))
                email.email_body = this.email.email_body.replace(
                    /\n\r?/g,
                    '<br>'
                )
                console.log({
                    ...this.extraParamsDatajson,
                    modulo: this.modulo.toLowerCase(),
                    campos: this.fieldsAvailables[this.format],
                    usuario_id: this.getUserData.id,
                    empresa_id: this.getUltimaEmpresa.id,
                    options: {
                        file_name: `reporte-${
                            this.modulo
                        }-${new Date().getTime()}.${
                            this.extensionsFormats[this.format]
                        }`,
                        ...email
                    }
                })
                API.POST({
                    url: this.url,
                    data: {
                        p_datajson: {
                            ...this.extraParamsDatajson,
                            modulo: this.modulo.toLowerCase(),
                            campos: this.fieldsAvailables[this.format],
                            usuario_id: this.getUserData.id,
                            empresa_id: this.getUltimaEmpresa.id,
                            options: {
                                file_name: `reporte-${
                                    this.modulo
                                }-${new Date().getTime()}.${
                                    this.extensionsFormats[this.format]
                                }`,
                                ...email
                            }
                        },
                        p_opc: this.alternativeOpc || this.format.toUpperCase(),
                        p_auditoriajson: {
                            usuario_id: this.getUserData.id,
                            sede_id: this.getUltimaSede.id
                        }
                    }
                })
                    .then((response) => {
                        console.log(response.data)
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.close()
                            // this.fieldsAvailables = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.email.$touch()
            }
        },
        close() {
            this.$bvModal.hide(this.id)
            this.email = defaultEmail()
            this.setEmailData()
            setTimeout(() => {
                this.$v.email.$reset()
            }, 500)
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.email[key]
            return $dirty ? !$error : null
        },
        validateStateTo() {
            const { $dirty, $error } = this.$v.to
            return $dirty ? !$error : null
        },
        validateStateFormat() {
            const { $dirty, $error } = this.$v.format
            return $dirty ? !$error : null
        }
    }
}
</script>

<style></style>
