<template>
    <b-modal
        id="modal-fields-report"
        header-bg-variant="primary"
        hide-footer
        title="Seleccione los campos del reporte"
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
    >
        <b-card class="p-3" style="height: 40rem; overflow-y: scroll">
            <b-list-group>
                <b-list-group-item
                    v-for="(field, index) in fields"
                    :key="index"
                    @click="addRemoveItem(field)"
                    class="d-flex justify-content-between align-items-center"
                >
                    {{ field }}
                    <feather
                        v-b-popover.hover.top="'No seleccionada'"
                        v-if="copy.indexOf(field) === -1"
                        type="square"
                        role="button"
                        class="mr-2 text-secondary"
                    ></feather>
                    <feather
                        v-else
                        v-b-popover.hover.top="'Sede seleccionada'"
                        type="check-square"
                        role="button"
                        class="mr-2 text-blue-active"
                    ></feather>
                </b-list-group-item>
            </b-list-group>
        </b-card>
        <div class="mt-5 float-right">
            <b-button
                variant="secondary"
                :disabled="isLoading"
                size="md"
                class="mt-4 mr-4"
                @click="close"
            >
                Cancelar
            </b-button>

            <b-button
                variant="primary"
                :disabled="isLoading"
                size="md"
                class="mt-4"
                @click="prepareReport"
            >
                <span
                    v-if="isLoading"
                    class="d-flex align-items-center justify-content-center"
                >
                    <b-spinner small></b-spinner>
                    <span class="ml-2">Generando...</span>
                </span>
                <span v-else>Generar Reporte</span>
            </b-button>
        </div>
    </b-modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import axios from 'axios'
import { getFileMetaData } from '@/utils/others'
import services from '@/boot/axios'

const { API } = services

export default {
    name: 'FieldsReport',
    data: () => ({
        copy: [],
        isLoading: false
    }),
    props: {
        fields: { type: Array, default: () => [] },
        modulo: { type: String, default: '' },
        format: { type: String, default: '' },
        aditionalFields: { type: Object, default: () => ({}) }
    },
    watch: {
        fields(newValue) {
            this.copy = [...newValue]
        }
    },
    methods: {
        addRemoveItem(field) {
            const index = this.copy.indexOf(field)
            if (index === -1) {
                this.copy.push(field)
            } else {
                this.copy.splice(index, 1)
            }
        },
        prepareReport() {
            this.isLoading = true
            API.POST({
                url: 'report/generate',
                data: {
                    p_datajson: {
                        ...this.aditionalFields,
                        modulo: this.modulo,
                        campos: [...this.copy],
                        usuario_id: this.getUserData.id,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: this.format.toUpperCase(),
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    // console.log(response.data);
                    this.isLoading = false
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        const { ext, fileData } = getFileMetaData(dato)
                        var a = document.createElement('a')
                        a.href = fileData
                        a.download = `report-${
                            this.modulo
                        }-${new Date().getTime()}.${ext}`
                        a.click()
                        this.$bvModal
                            .msgBoxOk('Archivo generado correctamente', {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                    this.close()
                })
                .catch((error) => {
                    this.isLoading = false
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        close() {
            this.copy = [...this.fields]
            this.$bvModal.hide('modal-fields-report')
            this.$emit('close')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 36 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
